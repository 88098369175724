import { Text } from '@chakra-ui/react';

import { useMusicPlayerState } from './context/use-music-player-state.hook';

const formatDuration = (template: string, raw: number) => {
  if (raw >= Infinity) {
    return '∞';
  }

  const formatTimeSegment = (time: number) => String(time).padStart(2, '0');

  const hours = Math.floor(raw / (60 * 60));
  const minutes = Math.floor((raw - hours) / 60);
  const seconds = Math.floor(raw % 60);

  return template
    .replace('HH', formatTimeSegment(hours))
    .replace('MM', formatTimeSegment(minutes))
    .replace('SS', formatTimeSegment(seconds));
};

export const MusicPlayerTimeDisplay = () => {
  const { currentTime, duration, timeFormat } = useMusicPlayerState();
  return (
    <Text fontSize="0.9rem" whiteSpace="nowrap">
      {formatDuration(timeFormat, currentTime)} /{' '}
      {formatDuration(timeFormat, duration)}
    </Text>
  );
};

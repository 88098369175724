import { graphql, PageProps } from 'gatsby';

import { StandardLayout } from '~/components/layout';
import { CreativeWorkGroup } from '~/components/creative-work';

const MusicPage = ({ data }: PageProps<Queries.MusicPageQuery>) => {
  const creativeWorks = data.contentfulPerson?.creativeWorks ?? [];

  return (
    <StandardLayout>
      {creativeWorks.map((group, index) =>
        !group ? (
          <></>
        ) : (
          <CreativeWorkGroup
            key={group.title ?? 'unknown title' + index}
            group={group}
          />
        )
      )}
    </StandardLayout>
  );
};

export default MusicPage;

export const pageQuery = graphql`
  query MusicPage {
    contentfulPerson {
      creativeWorks {
        ...CreativeWorkGroup
      }
    }
  }
`;

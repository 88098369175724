import { useContext, Context } from 'react';

const composeDefaultErrorMessage = <C>(context: Context<C>) => {
  const displayName =
    'displayName' in context ? `the '' context` : 'this context';
  return `you cannot use ${displayName} without its appropriate provider`;
};

/**
 * Like React.useContext, but throws an error if the passed in context value is null. By default, an
 * error message like 'you cannot use this context without its appropriate provider' is thrown, but
 * this message can be overridden with the second parameter.
 */
export const useEnsuredContext = <C>(
  context: Context<C | null>,
  message = composeDefaultErrorMessage(context)
) => {
  const contextValue = useContext(context);
  if (contextValue === null) {
    throw new Error(message);
  }

  return contextValue;
};

import { IconButton } from '@chakra-ui/react';

import { useMusicPlayerBehaviour } from './context/use-music-player-behaviour.hook';
import { useMusicPlayerState } from './context/use-music-player-state.hook';
import { Play, Pause } from '~/components/icons';

export const MusicPlayerControls = () => {
  const { isPlaying } = useMusicPlayerState();
  const { handlePlay, handlePause } = useMusicPlayerBehaviour();

  const IconComponent = isPlaying ? Pause : Play;
  const handleClick = isPlaying ? handlePause : handlePlay;

  return (
    <IconButton
      variant="unstyled"
      aria-label={isPlaying ? 'pause' : 'play'}
      icon={<IconComponent size="lg" />}
      onClick={handleClick}
    />
  );
};

import { createContext } from 'react';

export interface MusicPlayerState {
  duration: number;
  currentTime: number;
  isPlaying: boolean;
  timeFormat: string;
}

export const MusicPlayerStateContext = createContext<MusicPlayerState | null>(
  null
);

export interface MusicPlayerBehaviour {
  handlePlay: () => void;
  handlePause: () => void;
  handleStop: () => void;
  handleSeek: (time: number) => void;
}

export const MusicPlayerBehaviourContext =
  createContext<MusicPlayerBehaviour | null>(null);

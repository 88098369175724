import { Box, HStack, Text } from '@chakra-ui/react';

import { AssetImage } from '~/components/asset-image';
import { MusicPlayerProvider } from './context/music-player.provider';
import { MusicPlayerTimeDisplay } from './music-player-time-display.component';
import { MusicPlayerTrack } from './music-player-track.component';
import { MusicPlayerControls } from './music-player-controls.component';

export interface MusicPlayerProps {
  music: PartialContentfulObject<
    Queries.ContentfulMusic,
    'name' | 'music' | 'image'
  >;
  shouldShowCoverImage?: boolean;
}

export const MusicPlayer = ({
  music,
  shouldShowCoverImage = false,
}: MusicPlayerProps) => (
  <>
    {music.music?.url && (
      <MusicPlayerProvider timeFormat="MM:SS" src={music.music.url}>
        <Box>
          {shouldShowCoverImage && music.image && (
            <AssetImage
              image={music.image}
              aspectRatio={3 / 2}
              alt={`${music.name} cover image`}
            />
          )}
          <Text mt={2}>{music.music?.title ?? 'Unknown number'}</Text>
          <HStack width="100%">
            <MusicPlayerControls />
            <MusicPlayerTrack />
            <MusicPlayerTimeDisplay />
          </HStack>
        </Box>
      </MusicPlayerProvider>
    )}
  </>
);

import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';

import { useMusicPlayerState } from './context/use-music-player-state.hook';
import { useMusicPlayerBehaviour } from './context/use-music-player-behaviour.hook';

export const MusicPlayerTrack = () => {
  const { currentTime, duration } = useMusicPlayerState();
  const { handleSeek } = useMusicPlayerBehaviour();

  const handleChangeEnd = (newTime: number) => {
    handleSeek(newTime);
  };

  return (
    <Slider
      defaultValue={currentTime}
      max={duration}
      onChangeEnd={handleChangeEnd}
      sx={{
        '--slider-thumb-size': '0.4rem',
        '--slider-expand-size': '1.8',
        '--chakra-shadows-outline': 'var(--chakra-shadows-base)',
        _hover: {
          '.chakra-slider__thumb': {
            transform: 'translateY(-50%) scale(var(--slider-expand-size))',
          },
        },
      }}>
      <SliderTrack height="0.4rem">
        <SliderFilledTrack background="brand.chelseaGem" />
      </SliderTrack>
      <SliderThumb
        bgColor="brand.chelseaGem"
        _active={{
          transform: 'translateY(-50%) scale(var(--slider-expand-size))',
        }}
      />
    </Slider>
  );
};

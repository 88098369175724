import { useMemo } from 'react';
import { graphql } from 'gatsby';
import { groupBy } from 'lodash';
import { Text, Box, HStack, VStack } from '@chakra-ui/react';

import { MusicPlayer } from '~/components/music-player';
import { RichText } from '~/components/rich-text';
import { ExternalVideo } from '~/components/video-player';
import { isRichText } from '~/utilities/rich-text.utility';
import { useIsMobile } from '~/hooks/misc';

export interface CreativeWorkProps {
  creativeWork: Queries.CreativeWorkFragment;
  compact?: boolean;
}

interface LayoutProps {
  Title: JSX.Element;
  Description: JSX.Element;
  Subject: JSX.Element;
}

const CompactLayout = ({ Title, Description, Subject }: LayoutProps) => (
  <VStack spacing={2} alignItems="stretch">
    <Box height={['auto', 'auto', 12]}>{Title}</Box>
    <Box height={['auto', 'auto', 12]}>{Description}</Box>
    <Box pt="2">{Subject}</Box>
  </VStack>
);

const FullLayoutMobile = ({ Title, Description, Subject }: LayoutProps) => (
  <VStack spacing={2} alignItems="stretch">
    <Box>{Title}</Box>
    <Box>{Subject}</Box>
    {Description}
  </VStack>
);

const FullLayoutDesktop = ({ Title, Description, Subject }: LayoutProps) => (
  <HStack alignItems="flex-start" position="relative" spacing={6}>
    <Box flex="1 0 0">
      <Box mb="2">{Title}</Box>
      {Description}
    </Box>
    <Box>{Subject}</Box>
  </HStack>
);

const FullLayout = (props: LayoutProps) => {
  const isMobile = useIsMobile();
  const Layout = isMobile ? FullLayoutMobile : FullLayoutDesktop;
  return <Layout {...props} />;
};

export const CreativeWork = ({
  creativeWork,
  compact = false,
}: CreativeWorkProps) => {
  const { music, videos } = useMemo(() => {
    const grouped = groupBy(creativeWork.subjects, '__typename');

    return {
      videos: (grouped.ContentfulExternalVideo ??
        []) as Queries.CreativeWorkSubjectExternalVideoFragment[],
      music: (grouped.ContentfulMusic ??
        []) as Queries.CreativeWorkSubjectMusicFragment[],
    };
  }, [creativeWork.subjects]);

  const Layout = compact ? CompactLayout : FullLayout;

  return (
    <Layout
      Title={
        creativeWork.title ? (
          <Text as="h3" textStyle="sectionSubtitle">
            {creativeWork.title}
          </Text>
        ) : (
          <></>
        )
      }
      Description={
        isRichText(creativeWork.description) ? (
          <RichText content={creativeWork.description} />
        ) : (
          <></>
        )
      }
      Subject={
        videos.length > 0 ? (
          <ExternalVideo
            video={videos[0]}
            width={{ sm: '100%', lg: '30.5rem' }}
            height="18.5rem"
          />
        ) : (
          <VStack width={{ sm: '100%', lg: '20rem' }}>
            {music.map((music) => (
              <MusicPlayer
                key={music.name}
                music={music}
                shouldShowCoverImage={compact}
              />
            ))}
          </VStack>
        )
      }
    />
  );
};

export const query = graphql`
  fragment CreativeWorkSubjectExternalVideo on ContentfulExternalVideo {
    __typename
    name
    source
  }

  fragment CreativeWorkSubjectMusic on ContentfulMusic {
    __typename
    name
    image {
      title
      gatsbyImageData(placeholder: BLURRED)
    }
    music {
      url
      filename
      title
    }
  }

  fragment CreativeWork on ContentfulCreativeWork {
    title
    description {
      raw
    }
    subjects {
      ...CreativeWorkSubjectExternalVideo
      ...CreativeWorkSubjectMusic
    }
  }
`;

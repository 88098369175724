import { useReducer } from 'react';
import { MusicPlayerState } from './music-player.context';

type Action =
  | { type: 'setDuration'; time: number }
  | { type: 'setCurrentTime'; time: number }
  | { type: 'setTimeFormat'; format: string }
  | { type: 'play' }
  | { type: 'stop' };

const reducer = (state: MusicPlayerState, action: Action): MusicPlayerState => {
  switch (action.type) {
    case 'setDuration':
      return { ...state, duration: action.time };
    case 'setCurrentTime':
      return { ...state, currentTime: action.time };
    case 'setTimeFormat':
      return { ...state, timeFormat: action.format };
    case 'play':
      return { ...state, isPlaying: true };
    case 'stop':
      return { ...state, isPlaying: false };
    default:
      return state;
  }
};

export const useMusicPlayerReducer = (
  initialState: Partial<MusicPlayerState> = {}
) =>
  useReducer(reducer, {
    duration: Infinity,
    currentTime: 0,
    isPlaying: false,
    timeFormat: '',
    ...initialState,
  } as MusicPlayerState);

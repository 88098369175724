import { Box } from '@chakra-ui/react';
import { PropsWithChildren, useEffect, useMemo, useRef } from 'react';

import {
  MusicPlayerStateContext,
  MusicPlayerBehaviourContext,
} from './music-player.context';
import { useMusicPlayerReducer } from './use-music-player-reducer.hook';

export interface MusicPlayerProviderProps {
  src: string;
  timeFormat: string;
}

export const MusicPlayerProvider = ({
  src,
  timeFormat,
  children,
}: PropsWithChildren<MusicPlayerProviderProps>) => {
  const [state, dispatch] = useMusicPlayerReducer({ timeFormat });

  const ref = useRef<HTMLAudioElement | null>(null);

  const behaviour = useMemo(() => {
    const handlePlay = () => {
      dispatch({ type: 'play' });
      ref.current?.play();
    };

    const handleStop = () => {
      ref.current?.pause();
      if (ref.current) {
        dispatch({ type: 'setCurrentTime', time: 0 });
        dispatch({ type: 'stop' });
        ref.current.currentTime = 0;
      }
    };

    const handlePause = () => {
      ref.current?.pause();
      dispatch({ type: 'stop' });
    };

    const handleSeek = (value: number) => {
      if (ref.current) {
        dispatch({ type: 'setCurrentTime', time: value });
        ref.current.currentTime = value;
      }
    };

    return { handlePlay, handleStop, handleSeek, handlePause };
  }, [dispatch]);

  useEffect(() => {
    const player = ref.current;

    const handleMetadataLoaded = () =>
      dispatch({ type: 'setDuration', time: player?.duration ?? 0 });

    const handleTimeUpdate = () =>
      dispatch({ type: 'setCurrentTime', time: player?.currentTime ?? 0 });

    const handlePlay = () => () => dispatch({ type: 'play' });

    player?.addEventListener('timeupdate', handleTimeUpdate);
    player?.addEventListener('loadedmetadata', handleMetadataLoaded);
    player?.addEventListener('play', handlePlay);

    return () => {
      player?.removeEventListener('timeupdate', handleTimeUpdate);
      player?.removeEventListener('loadedmetadata', handleMetadataLoaded);
      player?.removeEventListener('play', handlePlay);
    };
  });

  return (
    <MusicPlayerBehaviourContext.Provider value={behaviour}>
      <MusicPlayerStateContext.Provider value={state}>
        <Box width="100%">
          <audio ref={ref} src={src} />
          {children}
        </Box>
      </MusicPlayerStateContext.Provider>
    </MusicPlayerBehaviourContext.Provider>
  );
};

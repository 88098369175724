import { useMemo } from 'react';
import { Box, BoxProps, Text, useTheme } from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import { get } from 'lodash';

export interface ExternalVideoProps extends BoxProps {
  video: PartialContentfulObject<
    Queries.ContentfulExternalVideo,
    'name' | 'source'
  >;
}

export const ExternalVideo = ({
  video,
  ...passThroughProps
}: ExternalVideoProps) => {
  const theme = useTheme();
  const captionHeight = useMemo(() => {
    return get(theme, 'textStyles.caption.lineHeight', '1rem');
  }, [theme]);

  return (
    <Box {...passThroughProps}>
      {video.source && (
        <ReactPlayer
          width="100%"
          height={`calc(100% - ${captionHeight})`}
          url={video.source}
          controls
        />
      )}
      <Text textStyle="caption">{video.name}</Text>
    </Box>
  );
};

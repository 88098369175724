import { SimpleGrid, VStack } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { useMemo } from 'react';

import { Section } from '~/components/section';
import { CreativeWork } from './creative-work.component';

interface CreativeWorkGroupProps {
  group: Queries.CreativeWorkGroupFragment;
}

const CreativeWorkGroupDefaultLayout = ({ group }: CreativeWorkGroupProps) => (
  <Section title={group?.title ?? 'Unkown group'}>
    <VStack spacing={10}>
      {group?.items?.map(
        (creativeWork) =>
          creativeWork?.title && (
            <CreativeWork
              key={creativeWork.title}
              creativeWork={creativeWork}
            />
          )
      )}
    </VStack>
  </Section>
);

// Turns a single CreativeWork into an array of CreativeWork objects, where each subject in the
// original creative work gets its own CreativeWork object. The title and description of all but the
// first item are set to null.
const spreadCreativeWorkSubjects = ({
  title,
  description,
  subjects,
}: Queries.CreativeWorkFragment): Queries.CreativeWorkFragment[] =>
  subjects?.map((subject, index) => ({
    subjects: [subject],
    ...(index > 0
      ? { title: null, description: null }
      : { title, description }),
  })) ?? [];

const CreativeWorkGroupGalleryLayout = ({ group }: CreativeWorkGroupProps) => {
  const gallerizedWorks = useMemo(() => {
    return (
      group.items?.flatMap((item) =>
        item ? spreadCreativeWorkSubjects(item) : []
      ) ?? []
    );
  }, [group]);

  return (
    <Section title={group?.title ?? 'Unkown group'}>
      <SimpleGrid columns={[1, 2, 3]} spacing={4}>
        {gallerizedWorks.map((creativeWork) => (
          <CreativeWork
            key={creativeWork?.title}
            creativeWork={creativeWork}
            compact
          />
        ))}
      </SimpleGrid>
    </Section>
  );
};

export const CreativeWorkGroup = ({ group }: CreativeWorkGroupProps) => {
  const Layout = group.displayInGalleryMode
    ? CreativeWorkGroupGalleryLayout
    : CreativeWorkGroupDefaultLayout;

  return <Layout group={group} />;
};

export const query = graphql`
  fragment CreativeWorkGroup on ContentfulCreativeWorkGroup {
    title
    displayInGalleryMode
    items {
      ...CreativeWork
    }
  }
`;
